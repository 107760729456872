<template>
  <div class="wrap">
    <alert-modal
      v-if="isAlertModal"
      :alertText="`선호음악은 최대 10개까지 선택가능합니다.`"
      @closeModal="onCloseAlertModal()"
    />
    <!-- 재생중인 음악이 있을 경우 미리듣기 사용 팝업-->
    <CommonConfirm
      v-if="isPlayStop"
      :confirmid="'playStop'"
      @close-modal="onCloseModal"
      @confirm-modal="onConfirmModal"
    >
      <template #contents>
        <div class="stopmodal_body">
          <h4 class="stopmodal_text1" v-html="'재생중이 음악이 있을 경우,'"></h4>
          <h4 class="stopmodal_text2" v-html="'미리듣기를 이용할 수 없습니다.'"></h4>
          <h4 class="stopmodal_text3" v-html="'재생중인 음악을 중지해드릴까요?'"></h4>
        </div>
      </template>
    </CommonConfirm>
    <!-- 자동추천 팝업-->
    <CommonConfirm
      v-if="isAutoRecomm"
      :confirmid="'autoRecomm'"
      @close-modal="onAutoRecommCloseModal"
      @confirm-modal="onAutoRecommConfirmModal"
    >
      <template #contents>
        <div class="stopmodal_body">
          <h4 class="stopmodal_text1" v-html="'선호자동추천으로 변경하시면 선택하신'"></h4>
          <h4 class="stopmodal_text2" v-html="'‘음악‘ 정보가 모두 선택 해제됩니다.'"></h4>
          <h4 class="stopmodal_text3" v-html="'선택하신 음악으로 계속 하시겠습니까?'"></h4>
        </div>
      </template>
    </CommonConfirm>

    <cancel-modal v-if="cancelStatus" />
    <video id="previewvideo" hidden autoplay playsinline></video>
    <!-- 중간 저장 팝업1 : 카드등록X AND 체험상태  -->
    <CommonConfirmVertical
      v-if="cancelCheckStatus && !isRegistCard && trialStatus"
      @confirm-yes="cancelCheckStatus = false"
      @confirm-no="onCancelCheckStatusConfirmYes"
    >
      <template #contents>
        <h4 class="common_alertbox_contents">
          거의 다왔어요! <br />
          그래도 그만하시겠어요?
        </h4>
      </template>
      <template #confirmyes>
        그렇다면 계속 할래요
      </template>
      <template #confirmno>
        나중에 할래요
      </template>
    </CommonConfirmVertical>
    <!-- 중간 저장 팝업2 : 나머지경우  -->
    <CommonConfirm
      v-else-if="cancelCheckStatus"
      :confirmid="'cancelcheckmodal'"
      @close-modal="onCancelCheckStatusConfirmNo"
      @confirm-modal="onCancelCheckStatusConfirmYes"
    >
      <template #contents>
        <div class="stopmodal_body">
          <h3 class="stopmodal_text1">
            등록중인 스페이스를 저장할까요?
          </h3>
        </div>
      </template>
    </CommonConfirm>
    />
    <!-- ################### PC ################### -->
    <div class="spaceinsert_pc">
      <section class="space_register_box scroll">
        <div class="space_register_title_box dp_flex flex_space_between">
          <h1 class="space_register_title">{{ registerTitle }}</h1>
          <div @click="linkMySpace()" class="dp_flex align_center cursor">
            <img src="/media/img/space/close.svg" alt="닫기" />
          </div>
        </div>
        <h3 v-if="isNewSpace" class="space_register_subtitle font_white">선호하는 음악을 선택해주세요. (3~10개)</h3>
        <div v-if="isNewSpace" class="space_progress_bar_wrap">
          <progress-bar :progresswidth="100" />
        </div>
        <h3 class="favorite_sub_title">앨범 이미지를 클릭하여 음악을 미리 들어보세요.</h3>
        <favorite-loading v-if="isLoading"></favorite-loading>
        <ul v-else class="dp_flex fade-up favorite_list flex_wrap">
          <li
            class="favorite_item relative "
            v-for="(item, index) in favoiteList"
            :key="item.musicId"
            :class="{ first: (index + 1) % 5 === 1, last: (index + 1) % 5 === 0 }"
          >
            <figure class="favorite_item_img_wrap" :id="'previewmusic' + index">
              <img
                @mouseenter.prevent="onPreviewMouseEnter(index, $event)"
                class="favorite_item_img cursor"
                :src="item.imgUrl"
                alt="Sweet Carolina"
              />
              <div
                v-if="selectPreferMusic.includes(item.musicId)"
                class="favorite_active"
                @mouseenter.prevent="onPreviewMouseEnter(index, $event)"
              >
                <img class="favorite_hart" src="/media/img/space/hart.png" alt="좋아요" />
              </div>
            </figure>
            <transition name="fade">
              <div
                v-if="item.isActive"
                class="favorite_hover cursor"
                @click="onClickSelectPrefer(item, $event)"
                @mouseleave.prevent="onPreviewMouseLeave(index, $event)"
              >
                <figure id="playBtnWrap" class="preview_btn" v-if="!item.isPlay">
                  <img
                    id="playBtn"
                    src="/media/img/play_btn.svg"
                    alt="플레이버튼"
                    @click.prevent="onMusicPlay(index)"
                  />
                </figure>
                <div id="rythmWrap" class="rythm_wrap" v-if="item.isPlay" @click.prevent="onMusicPlay(index)">
                  <ul id="rythmItem" class="rythm cursor">
                    <li id="rythmItem1" class="rythm_item"></li>
                    <li id="rythmItem2" class="rythm_item second"></li>
                    <li id="rythmItem3" class="rythm_item last"></li>
                  </ul>
                </div>
              </div>
            </transition>
            <strong class="farvorite_item_song_title">{{ item.musicNm }}</strong>
            <span class="farvorite_item_song_artist">{{ item.artist }}</span>
          </li>
        </ul>
      </section>
    </div>
    <!-- ################### 모바일 ################### -->
    <div class="spaceinsert_mo favorite" :class="{ is_no_newspace: !isNewSpace }">
      <!-- 모바일 헤더-->
      <mo-spaceheader v-if="isNewSpace" @click-pre="onClickPre" @link-myspace="linkMySpace">
        <template #progressbar>
          <progress-bar :progresswidth="100" />
        </template>
      </mo-spaceheader>
      <!-- 모바일 바디 -->
      <section class="mo_spaceinsert_body">
        <h3 class="mo_spaceinsert_title mo_favorite_title">선호 음악 선택 (3~10개)</h3>
        <h3 class="favorite_sub_title">길게 눌러 음악을 들어보세요.</h3>
        <img
          v-if="!isNewSpace"
          src="/media/images/icon_close_24.svg"
          alt="닫기"
          class="close_btn"
          @click="routerBack"
        />
        <favorite-loading v-if="isLoading"></favorite-loading>
        <ul v-else class="dp_flex fade-up favorite_list flex_wrap">
          <li
            class="favorite_item relative "
            v-for="(item, index) in favoiteList"
            :key="item.musicId"
            :class="{ first: (index + 1) % 3 === 1, last: (index + 1) % 3 === 0 }"
          >
            <figure
              class="favorite_item_img_wrap"
              :id="'previewmusic' + index"
              @touchstart="onTouchStart(item, $event)"
              @touchend="onTouchEnd(item, $event)"
              @contextmenu.prevent="
                () => {
                  return false;
                }
              "
            >
              <img class="favorite_item_img cursor" :src="item.imgUrl" alt="Sweet Carolina" />
              <div v-if="selectPreferMusic.includes(item.musicId)" class="favorite_active">
                <img class="favorite_hart" src="/media/img/space/hart.png" alt="좋아요" />
              </div>
            </figure>
            <transition name="fade">
              <div
                v-if="item.isActive"
                class="favorite_hover cursor"
                @click.prevent="onFavoriteClick(item)"
                :class="{ first: (index + 1) % 3 === 1, middle: (index + 1) % 3 === 2, last: (index + 1) % 3 === 0 }"
              >
                <div id="rythmWrap" class="rythm_wrap" v-if="item.isPlay" @click.prevent="onMusicPlay(index)">
                  <ul id="rythmItem" class="rythm cursor">
                    <li id="rythmItem1" class="rythm_item"></li>
                    <li id="rythmItem2" class="rythm_item second"></li>
                    <li id="rythmItem3" class="rythm_item last"></li>
                  </ul>
                </div>
              </div>
            </transition>
            <strong class="farvorite_item_song_title">{{ item.musicNm }}</strong>
            <span class="farvorite_item_song_artist">{{ item.artist }}</span>
          </li>
        </ul>
      </section>
    </div>
    <space-bottom
      :isshowbottom="false"
      :btndisabled="btnDisabled"
      :autorecommbtndisabled="autoRecommBtnDisabled"
      :isshowprebtn="isShowPreBtn"
      :cofirmbtntext="confirmBtnText"
      @show-curationinfo="onShowCurationinfo"
      @click-pre="onClickPre"
      @update-curation="onUpdateCuration"
      @auto-recommend="onAutoRecommend"
    />
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { getCurationInfo } from '@/service/api/playerApi';
import CommonConfirm from '@/components/common/CommonConfirm.vue';
import CommonConfirmVertical from '@/components/common/CommonConfirmVertical.vue';
import {
  getPreferMusicList,
  checkSubscribe,
  selectSimilarMusicList,
  automaticRecommOfPreferMusic,
  updatePreferMusicList,
  updateConfirmCuration,
  checkOverlappingTime,
  getCurationTemp,
  deleteCurationInfo,
  updateStandByCuration
} from '@/service/api/profileApi';
import { getMyCardInfo } from '@/service/api/productApi';
import { isNotPc, retryAutomaticRecomm } from '@/utils/Utils';
import Hls from 'hls.js';
export default defineComponent({
  props: {
    updatecurationinfo: {
      type: Function,
      required: false,
      default: null
    },
    linkmyspace: {
      type: Function,
      required: false,
      default: null
    }
  },
  name: 'SpaceInsertFavorite',
  data() {
    return {
      spaceId: localStorage.getItem('spaceId'),
      userId: this.$cookies.get('userId'),
      curationId: null,
      favoiteList: [],
      curationObject: null,
      isRegistCard: false,
      cancelCheckStatus: false,
      cancelStatus: false,
      isPc: !isNotPc(),
      config: {
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90
      },
      curationlist: [],
      previewvideo: null,
      hls: null,
      isPlayStop: false,
      selectItem: null,
      curationInfo: null,
      showCurationInfo: false,
      selectPreferMusic: [],
      isLoading: false,
      isAutoRecomm: false,
      checkAutoRecomm: false,
      isInstalledPlayer: false,
      autoRecommBtnDisabled: false,
      bottomBtnDisabled: false,
      isNewSpace: true,
      registerTitle: '스페이스 등록',
      isShowPreBtn: true,
      confirmBtnText: '다음',
      startTime: null,
      endTime: null,
      isAlertModal: false
    };
  },
  async created() {
    if (Object.keys(this.$route.params).length > 0) {
      const { spaceid, curationid } = this.$route.params;
      this.spaceId = spaceid;
      this.curationId = curationid;
      if (this.curationId) this.getCurationList(this.curationId);
      this.registerTitle = '선호 음악 선택 (3~10개)';
      this.isShowPreBtn = false;
      this.confirmBtnText = '완료';
      this.isNewSpace = false;
      this.$forceUpdate();
    }
    if (this.$store.state.installablePlayer) this.isInstalledPlayer = true;
    this.checkRegistCard();
    await this.setCurationInfo();
    this.setPreferMusicList();
  },
  async mounted() {
    this.setPreviewVideo();
  },
  components: {
    CommonConfirm,
    CommonConfirmVertical,
    'mo-spaceheader': () => import(`@/components/common/MoSpaceHeader.vue`),
    'space-bottom': () => import(`@/components/space/SpaceBottom.vue`),
    'progress-bar': () => import(`@/components/space/ProgressBar.vue`),
    'favorite-loading': () => import(`@/components/space/FavoriteLoading.vue`),
    'alert-modal': () => import(`@/components/modal/Space/AlertModal.vue`)
  },
  methods: {
    onCloseAlertModal() {
      this.isAlertModal = false;
    },
    async getCurationList(curationId) {
      try {
        const { data } = await getCurationTemp(curationId);
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          const { spaceId, dayOfWeek, startTime, endTime } = result;
          if (dayOfWeek && startTime && endTime) {
            this.startTime = startTime;
            this.endTime = endTime;
            const { data: overlappData } = await checkOverlappingTime(spaceId, dayOfWeek, startTime, endTime);
            const { resultCd: overlappResultCd, result: overlappResult } = overlappData;
            if (overlappResultCd === '0000') {
              const list = overlappResult || [];
              const filterList = list.filter(item => item !== this.curationId);
              if (filterList.length > 0) {
                this.curationlist = filterList;
              }
            } else {
              return false;
            }
          }
        } else {
          return false;
        }
      } catch (error) {
        this.curationlist = [];
        return false;
      }
    },
    routerBack() {
      this.$router.go(-1);
    },
    onAutoRecommend() {
      if (this.selectPreferMusic.length > 0) {
        this.isAutoRecomm = true;
      } else {
        this.onAutoRecommConfirmModal();
      }
    },
    onAutoRecommCloseModal() {
      this.isAutoRecomm = false;
    },
    onAutoRecommConfirmModal() {
      this.isAutoRecomm = false;
      this.selectPreferMusic = [];
      this.checkAutoRecomm = true;

      this.onUpdateCuration();
    },
    onClickSelectPreferMobile(paramItem) {
      const { musicId } = paramItem;
      const findIdx = this.selectPreferMusic.findIndex(item => item === musicId);

      if (findIdx > -1) {
        this.selectPreferMusic.splice(findIdx, 1);
      } else {
        if (this.selectPreferMusic.length >= 10) {
          this.isAlertModal = true;
          return true;
        }
        this.selectPreferMusic.push(musicId);

        selectSimilarMusicList(this.spaceId, musicId, this.favoiteList.map(item => item.musicId).join(',')).then(
          res => {
            const { resultCd, result } = res.data;
            if (resultCd === '0000') {
              const returnList = this.getFavorite(result);
              const findIndex = this.favoiteList.findIndex(item => item.musicId === musicId);
              const firstSlice = this.favoiteList.slice(0, findIndex + 1);
              const lastSlice = this.favoiteList.slice(findIndex + 1);
              this.favoiteList = [...firstSlice, ...returnList, ...lastSlice];
            }
          }
        );
      }
    },
    onClickSelectPrefer(paramItem, $event) {
      if ($event) {
        const { id } = $event.target;
        if (id === 'playBtn' || id === 'rythmWrap' || id.includes('rythmItem')) return;
      }
      const { musicId, isNotRecommendSong, isCheckRecommendSong } = paramItem;
      const findIdx = this.selectPreferMusic.findIndex(item => item === musicId);

      if (findIdx > -1) {
        this.selectPreferMusic.splice(findIdx, 1);
      } else {
        if (this.selectPreferMusic.length >= 10) {
          this.isAlertModal = true;
          return true;
        }
        this.selectPreferMusic.push(musicId);

        if (isNotRecommendSong && !isCheckRecommendSong) {
          paramItem.isCheckRecommendSong = true;
          selectSimilarMusicList(this.spaceId, musicId, this.favoiteList.map(item => item.musicId).join(',')).then(
            res => {
              const { resultCd, result } = res.data;
              if (resultCd === '0000') {
                const returnList = this.getFavorite(result);
                const findIndex = this.favoiteList.findIndex(item => item.musicId === musicId);
                const firstSlice = this.favoiteList.slice(0, findIndex + 1);
                const lastSlice = this.favoiteList.slice(findIndex + 1);
                this.favoiteList = [...firstSlice, ...returnList, ...lastSlice];
              }
            }
          );
        }
      }
    },
    getFavorite(result = []) {
      const newArray = [];
      const len = result.length;
      for (let i = 0; i < len; i++) {
        result[i].isActive = false;
        result[i].isPlay = false;
        result[i].imgUrl = `${process.env.VUE_APP_BASE_URL}${result[i].albumImg}`;
        result[i].longTouchToggle = false;
        result[i].touchstarttime = null;
        result[i].isNotRecommendSong = false;
        result[i].isCheckRecommendSong = false;

        newArray.push(result[i]);
      }

      return newArray;
    },
    onCloseModal() {
      this.isPlayStop = false;
      this.selectItem = null;
    },
    onConfirmModal() {
      this.$VideoPlayer.onPause();
      const { musicId } = this.selectItem;
      // 켜져있는 것 종료
      this.resetPlayer(musicId);
      // 재생 처리
      this.isPlayStop = false;
      const len = this.favoiteList.length;
      for (let i = 0; i < len; i++) {
        const findIdx = this.favoiteList.findIndex(item => item.musicId === musicId);
        if (findIdx > -1) {
          const { musicId } = this.favoiteList[findIdx];
          this.favoiteList[findIdx].isActive = true;
          this.favoiteList[findIdx].isPlay = true;
          const musicPath = this.getMusicPath(musicId);
          this.setMusic(musicPath);

          this.previewvideo.play().catch(() => {
            this.favoiteList[findIdx].isPlay = false;
          });

          break;
        }
      }
    },
    setPreviewVideo() {
      this.previewvideo = document.querySelector('#previewvideo');
      if (Hls.isSupported()) {
        this.hls = new Hls(this.config);
      }
      this.previewvideo.addEventListener('ended', this.onVideoEnded);
    },
    onShowCurationinfo() {
      this.showCurationInfo = true;
    },
    onClickPre() {
      this.$router.push({ name: 'SpaceInsertStorePeriod' }).catch(() => {});
    },
    deleteTimeCuration() {
      try {
        const len = this.curationlist.length;
        for (let i = 0; i < len; i++) {
          deleteCurationInfo(this.curationlist[i]);
        }
      } catch (error) {
        console.error('deleteTimeCuration error : ', error);
      }
    },
    async onUpdateCuration() {
      this.autoRecommBtnDisabled = true;
      this.bottomBtnDisabled = true;
      if (this.curationlist.length > 0) this.deleteTimeCuration();
      try {
        const { data: confirmData } = await updateConfirmCuration(this.spaceId, this.curationId);
        const { resultCd } = confirmData;
        if (resultCd === '0000') {
          //
          if (this.checkAutoRecomm) {
            const { data } = await automaticRecommOfPreferMusic(this.curationId);
            const { resultCd } = data;
            if (resultCd !== '0000') return false;
          } else {
            const { data } = await updatePreferMusicList(this.spaceId, this.selectPreferMusic, this.curationId);
            const { resultCd } = data;
            if (resultCd !== '0000') return false;
          }

          localStorage.setItem('spaceId', this.spaceId);
          if (this.isInstalledPlayer) {
            this.$router.push({ name: 'SpaceInsertSelectChannel' }).catch(() => {});
          } else {
            this.$router.push({ path: `/space/insertStore5/${this.curationId}` }).catch(() => {});
          }
        }
      } catch (error) {
        if (error.response) {
          const { url } = error.response.config;
          if (url === '/api/profile/automaticRecommOfPreferMusic') {
            console.log(error.response);
            retryAutomaticRecomm(
              0,
              () => {
                this.autoRecommBtnDisabled = false;
                this.bottomBtnDisabled = false;
              },
              () => {
                this.autoRecommBtnDisabled = false;
                this.bottomBtnDisabled = false;
              }
            );
          }
          if (error.response.status === 500 && this.startTime && this.endTime && this.isNewSpace) {
            updateStandByCuration(this.curationId);
          }
        }
      } finally {
        this.autoRecommBtnDisabled = false;
        this.bottomBtnDisabled = false;
      }
    },
    checkRegistCard() {
      getMyCardInfo(this.userId)
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            result ? (this.isRegistCard = true) : (this.isRegistCard = false);
          } else {
            this.isRegistCard = false;
          }
        })
        .catch(() => {
          this.isRegistCard = false;
        });
    },
    async linkMySpace() {
      try {
        if (this.isNewSpace) {
          const { data } = await checkSubscribe(this.spaceId);
          const { resultCd, result } = data;
          if (resultCd === '0000') {
            if (result === 'S') {
              this.curationObject = {
                spaceId: this.spaceId
              };
              this.cancelCheckStatus = true;
            }
          } else {
            this.$router.push({ name: 'Player' }).catch(() => {});
          }
        } else {
          this.$router.push({ path: `/curationsetting/${this.spaceId}/${this.curationId}` }).catch(() => {});
        }
      } catch (error) {
        console.error('linkMySpace error : ', error);
      }
    },
    async setCurationInfo() {
      try {
        const { data } = await getCurationInfo(this.spaceId);
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          this.curationInfo = result;
          const { curationId } = result;
          if (this.isNewSpace) this.curationId = curationId;
        } else {
          this.curationId = null;
        }
      } catch (error) {
        console.error('setCurationInfo : ', error);
      }
    },
    setPreferMusicList() {
      this.isLoading = true;
      getPreferMusicList(this.curationId)
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            const len = result.length;
            for (let i = 0; i < len; i++) {
              result[i].isActive = false;
              result[i].isPlay = false;
              result[i].imgUrl = `${process.env.VUE_APP_BASE_URL}${result[i].albumImg}`;
              result[i].longTouchToggle = false;
              result[i].touchstarttime = null;
              result[i].isNotRecommendSong = true;
              result[i].isCheckRecommendSong = false;
              this.favoiteList.push(result[i]);
            }
          } else {
            this.favoiteList = [];
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onVideoEnded() {
      this.resetPlayer();
    },
    resetPlayer(musicId = null) {
      const len = this.favoiteList.length;
      for (let i = 0; i < len; i++) {
        const { musicId: diffMusicId } = this.favoiteList[i];
        if (musicId && diffMusicId === musicId) {
          continue;
        }
        this.favoiteList[i].isPlay = false;
        this.favoiteList[i].isActive = false;
      }
    },
    onPreviewMouseEnter(index) {
      const list = this.resetFavoriteList();

      this.favoiteList = list;
      if (this.favoiteList[index]) {
        this.favoiteList[index].isActive = true;
      }
    },
    onPreviewMouseLeave(index) {
      if (this.favoiteList[index]) {
        if (!this.favoiteList[index].isPlay) {
          this.favoiteList[index].isActive = false;
        }
      }
    },
    resetFavoriteList() {
      return this.favoiteList.map(item => {
        return {
          ...item,
          isActive: !!item.isPlay
        };
      });
    },
    onMusicPlay(index) {
      if (this.favoiteList[index]) {
        const { musicId, isPlay } = this.favoiteList[index];
        if (isPlay) {
          this.favoiteList[index].isPlay = false;
          this.favoiteList[index].isActive = false;
          this.favoiteList[index].longTouchToggle = false;
          this.previewvideo.pause();
        } else {
          if (this.$store.state.isPlay) {
            this.selectItem = this.favoiteList[index];
            // 플레이 모달 띄우기
            this.isPlayStop = true;
          } else {
            // 켜져있는 것 종료
            this.resetPlayer(musicId);

            // 새롭게 play
            this.favoiteList[index].isPlay = true;
            const musicPath = this.getMusicPath(musicId);

            this.setMusic(musicPath);

            this.previewvideo.play().catch(() => {
              this.favoiteList[index].isPlay = false;
            });
          }
        }
      }
    },
    getMusicPath(path) {
      const musicPath =
        window.location.host.indexOf('localhost') !== -1
          ? `http://13.209.152.125:10084/stream/getMusic?${path}`
          : `stream/getMusic?${path}`;

      return musicPath;
    },
    setMusic(musicPath) {
      if (Hls.isSupported()) {
        this.hls.loadSource(`${musicPath}`);
        this.hls.attachMedia(this.previewvideo);
      } else {
        this.previewvideo.src = musicPath;
      }
    },
    onTouchStart(item, $event) {
      $event.stopPropagation();
      item.touchstarttime = new Date();
      const { musicId } = item;
      if (!item.longTouchToggle) {
        item.longTouchToggle = true;
        setTimeout(() => {
          if (item.longTouchToggle) {
            if (this.$store.state.isPlay) {
              this.selectItem = item;
              this.isPlayStop = true;
            } else {
              this.$VideoPlayer.onPause();
              // 켜져있는 것 종료
              this.resetPlayer(musicId);
              const musicPath = this.getMusicPath(musicId);
              this.setMusic(musicPath);
              item.isPlay = true;
              item.isActive = true;

              this.previewvideo.play().catch(() => {
                item.isPlay = false;
              });
            }
          } else {
            item.isPlay = false;
            item.isActive = false;
          }
        }, 1500);
      } else {
        item.longTouchToggle = false;
      }
    },
    onTouchEnd(item, $event) {
      if ($event) {
        const { id } = $event.target;
        if (id === 'playBtn' || id === 'rythmWrap' || id.includes('rythmItem')) return;
      }
      const { touchstarttime } = item;

      const curTime = new Date();
      const diff = (curTime.getTime() - touchstarttime.getTime()) / 1000;
      const milseconds = Math.ceil(diff * 10) / 10;
      if (milseconds <= 0.5) {
        item.longTouchToggle = false;
        const { musicId } = item;
        const findIdx = this.selectPreferMusic.findIndex(item => item === musicId);
        if (findIdx > -1) {
          this.selectPreferMusic.splice(findIdx, 1);
        } else {
          this.selectPreferMusic.push(musicId);
          selectSimilarMusicList(this.spaceId, musicId, this.favoiteList.map(item => item.musicId).join(',')).then(
            res => {
              const { resultCd, result } = res.data;
              if (resultCd === '0000') {
                const returnList = this.getFavorite(result);
                const findIndex = this.favoiteList.findIndex(item => item.musicId === musicId);
                const firstSlice = this.favoiteList.slice(0, findIndex + 1);
                const lastSlice = this.favoiteList.slice(findIndex + 1);
                this.favoiteList = [...firstSlice, ...returnList, ...lastSlice];
              }
            }
          );
        }
      } else {
        item.longTouchToggle = false;
      }
    },
    onFavoriteClick(item) {
      const { musicId } = item;
      const findIdx = this.selectPreferMusic.findIndex(item => item === musicId);
      if (findIdx > -1) {
        this.selectPreferMusic.splice(findIdx, 1);
      } else {
        this.selectPreferMusic.push(musicId);
        selectSimilarMusicList(this.spaceId, musicId, this.favoiteList.map(item => item.musicId).join(',')).then(
          res => {
            const { resultCd, result } = res.data;
            if (resultCd === '0000') {
              const returnList = this.getFavorite(result);
              const findIndex = this.favoiteList.findIndex(item => item.musicId === musicId);
              const firstSlice = this.favoiteList.slice(0, findIndex + 1);
              const lastSlice = this.favoiteList.slice(findIndex + 1);
              this.favoiteList = [...firstSlice, ...returnList, ...lastSlice];
            }
          }
        );
      }
    },
    /**
     * @description 임시저장 팝업 아니오 클릭
     */
    async onCancelCheckStatusConfirmNo() {
      this.linkmyspace(this.spaceId);
    },
    /**
     * @description 임시저장 팝업 예 클릭
     */
    async onCancelCheckStatusConfirmYes() {
      const { isSuccess, msg } = await this.updatecurationinfo(this.curationObject);
      if (isSuccess) {
        this.cancelCheckStatus = false;
        this.cancelStatus = true;
      } else {
        this.onDeleteSpace(msg);
      }
    }
  },
  destroyed() {
    this.previewvideo.removeEventListener('ended', this.onVideoEnded);
  },
  computed: {
    btnDisabled() {
      if (this.bottomBtnDisabled) return true;

      if (this.checkAutoRecomm) {
        return false;
      } else {
        if (this.selectPreferMusic.length >= 3 && this.selectPreferMusic.length <= 11) {
          return false;
        }
        return true;
      }
    },
    trialStatus() {
      return this.$store.state.trialStatus;
    }
  }
});
</script>
<style scoped src="@/assets/css/space/spaceinsert.css"></style>
<style scoped src="@/assets/css/space/spacefavorite.css"></style>
<style scoped src="@/assets/css/modal/space/playstop.css"></style>
