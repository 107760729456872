import axios from 'axios';

/**
 * @description 내 카드 정보
 * @returns Promise
 */
const getMyCardInfo = async userId => {
  return await axios.post('/api/product/getMyCardInfo', { userId });
};

/**
 * @description 가격 표시
 * @param {*} spaceId
 * @returns
 */
const getProductPriceApi = async spaceId => {
  return await axios.post(`/api/product/getProductPrice`, { spaceId });
};

export { getMyCardInfo, getProductPriceApi };
